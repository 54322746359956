.campaign-preview-container {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding: 16px;
	color: #1f1f1f;
	font-family: 'Roboto', sans-serif;
	font-size: 0.6em;
}

.iphone-container {
	width: 66mm;
	height: 135mm;
	background-color: black;
	border-radius: 40px;
	border: 9px #004953 ridge;
	position: relative;
}

.screen {
	background-color: rgba(242, 242, 247, 0.9);
	box-sizing: border-box;
	width: 100%;
	height: 30px;
	border-radius: 35px 35px 0px 0px;
	padding: 10px;
	display: inline-grid;
	grid-template-rows: 1fr;
	grid-template-areas: "time . . network";
}

.topbar {
	width: 55%;
	height: 1.5rem;
	position: absolute;
	text-align: center;
	background-color: black;
	right: 70px;
	top: 0px;
	border-radius: 0px 0px 20px 20px;
}

.speaker {
	background-color: #101010;
	border-radius: 40px;
	height: 5px;
	width: 35px;
	display: inline-block;
	margin-top: 10px;
}

.flash {
	background-color: #101010;
	border-radius: 20px;
	height: 6px;
	width: 6px;
	display: inline-block;
	margin-left: 5px;
}


.time {
	grid-area: time;
	padding-top: 5px;
	margin-left: 2px;
	position: relative;
}

.network {
	grid-area: network;
	padding-right: 20px;
	padding-top: 2px;
	position: relative;
	margin-right: 5px;
}

.network5,
.network2,
.network3,
.network4 {
	background-color: #1f1f1f;
	height: 3px;
	width: 2px;
}

.network2,
.network3,
.network4,
.network5 {
	position: absolute;
	margin-left: 2px;
	margin-bottom: 5px;
}

.network2 {
	height: 5px;
	left: 18px;
	top: 8px;
}

.network3 {
	height: 7px;
	left: 21px;
	top: 6px;

}

.network4 {
	height: 9px;
	left: 24px;
	top: 4px;
}

.network5 {
	height: 11px;
	left: 27px;
	top: 2px;
}

.LTE {
	position: absolute;
	top: 2.5px;
	left: 33px;
	font-family: 'Roboto', sans-serif;
}

.battery {
	top: 4px;
	position: absolute;
	left: 52px;

}

.battery1 {
	background-color: #1f1f1f;
	width: 15px;
	height: 8px;
	border: 2px gray;
}

.battery2 {
	background-color: #1f1f1f;
	width: 1px;
	height: 3px;
	border: 1px gray;
	left: 16px;
	top: 2px;
	position: absolute;
}

.contact-section-wrapper {
	width: 100%;
	height: 60px;
	position: absolute;
	top: 30px;
	left: 0;
	z-index: 4;
	border-bottom: 1px solid lightgray;
	padding: 10px 0;
	background-color: rgba(242, 242, 247, 0.9);
	backdrop-filter: blur(4px);
}

.contact-section {
	display: flex;
	justify-content: center;
	position: sticky;
}

.back-caret {
	text-align: center;
	position: absolute;
	margin-left: 15px;
}

.icon-with-number {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}

.icon-wrapper {
	color: rgb(142, 142, 147);
}

.message-section {
	margin-top: 81px;
	background: #fff;
	height: 372px;
	border-radius: 0px 0px 35px 35px;
	font-size: 13px;
	font-weight: 600;
	padding: 12px 20px;
	overflow: scroll;
}

.message-preview {
	align-items: flex-start;
	background-color: #e5e5ea;
	color: #000;
	border-radius: 20px;
	padding: 8px 15px;
	margin-top: 12px;
	background-color: #eee;
	position: relative;
	white-space: pre-wrap;
	-ms-word-break: break-all;
	word-break: break-all;
	/* Non standard for webkit */
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}